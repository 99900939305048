<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-date"></i> {{$t('navbar.dataQuery')}}
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('navbar.withdrawalApplication')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div>
                <el-select class="filter-item" style="width: 160px" v-model="queryData.terminalId" placeholder="终端业务线">
                    <el-option v-for="item in terminalList" :key="item.id" :label="`${item.id}-${item.terminalName}`" :value="item.id">
                    </el-option>
                </el-select>
                <el-input style="width: 160px" clearable class="filter-item" placeholder="请输入手机号码" v-model="queryData.userName">
                </el-input>
                <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList()">查询</el-button>
            </div>
            <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>

                <el-table-column width="180" align="center" label="用户ID">
                    <template slot-scope="scope">
                        <span>{{scope.row.userid}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="产品ID" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.productid |productFmt}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="我方订单号" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.orderid }}</span>
                    </template>
                </el-table-column>
                <el-table-column width="120" align="center" label="申请金额" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.applyamount}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="120" align="center" label="授信金额" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.creditamount}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="提现状态">
                    <template slot-scope="scope">
                        <span>{{scope.row.status | statusType}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="80" align="center" label="期数">
                    <template slot-scope="scope">
                        <span>{{scope.row.period }}{{scope.row.periodunit | filterUnit}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="申请时间 ">
                    <template slot-scope="scope">
                        <span>{{scope.row.applytime | filtiema}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="放款时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.lendtime | filtiema}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="失败原因">
                    <template slot-scope="scope">
                        <span>{{scope.row.failurereason}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="备注">
                    <template slot-scope="scope">
                        <span>{{scope.row.remark}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="马甲包包体ID">
                    <template slot-scope="scope">
                        <span>{{scope.row.mjbpackageid}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="创建时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.createtime | filtiema}}</span>
                    </template>
                </el-table-column>
                <el-table-column width="180" align="center" label="更新时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.updatetime | filtiema}}</span>
                    </template>
                </el-table-column>

            </el-table>

        </div>
    </div>
</template>
<script>
import { splitterStageRuleRelLimitOptions, querywithdrawalListList } from "@/api/request";
const terminalMap = new Map();
const productMap = new Map();
const ruleMap = new Map();
const stageMap = new Map();
export default {
    name: "SplitterStageRuleRelLimit",
    data() {
        return {
            listLoading: false,
            queryData: { terminalId: undefined, userName: undefined },
            list: [],
            terminalList: [],
            productList: [],
        };
    },
    mounted() {
        this.getOptions().then(res => {
            this.queryData.terminalId=res.data[0].id
            this.terminalList = res.data;
            this.terminalList.forEach(e => terminalMap.set(e.id, e));
        });
    },
    filters: {
        statusType(v) {
            switch (v) {
                case 1:
                    return "1-已提交待送"
                    break;
                case 2:
                    return "2-提现中"
                    break;
                case 3:
                    return "3-提现成功"
                    break;
                case 4:
                    return "4-提现失败"
                    break;
                case 5:
                    return "5-提现拒绝（贷中风控)"
                    break;
            }
        },
        filterUnit(v) {
            switch (v) {
                case '1':
                    return "天"
                    break;
                case '2':
                    return "月"
                    break;
                case '3':
                    return "周"
                    break;
                case '4':
                    return "年"
                    break;
            }
        },
        productFmt(v) {
            let d = productMap.get(v);
            return `${v}${(d && d.productName) ? ('-' + d.productName) : ''}`;
        },

    },
    methods: {
        handleList() {
            if (!this.queryData.userName) {
                this.$message.error('请输入手机号码');
                return
            }
            this.fillQueryOptions()
            this.listLoading = true;
            querywithdrawalListList("get", this.queryData)
                .then(res => {
                    this.list = res.data.withdrawApplyList;
                    this.listLoading = false;
                })
                .catch(_ => (this.listLoading = false,this.list=[]));
        },
        async getOptions(terminalId) {
            return await splitterStageRuleRelLimitOptions(terminalId);
        },
        fillQueryOptions() {
            this.getOptions(this.queryData.terminalId).then(res => {
                this.productList = res.data.product;
                this.fillNameFilters(res.data);
            });
        },
        fillNameFilters(data) {
            productMap.clear();
            data.product && data.product.forEach(e => productMap.set(e.id, e));
        },

    }
};
</script>
<style lang="less" scoped>
.status-1-cls {
    color: #67c23a;
    font-weight: bold;
}
.status-0-cls {
    color: #f56c6c;
    font-weight: bold;
}
.extra-btn {
    float: right;
    margin-top: -72px;
    display: flex;
    margin-right: -30px;
    i {
        margin: auto 5px;
        font-size: larger;
        color: #909399;
    }
}
</style>
<style>
.custom-message {
    box-shadow: 0px 0px 10px #888888;
}
</style>
