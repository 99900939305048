<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-date"></i> {{$t('navbar.dataQuery')}}
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('navbar.publicInfo')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div>
                <el-select class="filter-item" style="width: 160px" v-model="queryData.terminalId" placeholder="终端业务线">
                    <el-option v-for="item in terminalList" :key="item.id" :label="`${item.id}-${item.terminalName}`" :value="item.id">
                    </el-option>
                </el-select>
                <el-input style="width: 160px" clearable class="filter-item" placeholder="请输入手机号码" v-model="queryData.userName">
                </el-input>
                <el-input style="width: 160px" clearable class="filter-item" placeholder="请输入字段编码" v-model="queryData.fieldCode">
                </el-input>
                <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList()">查询</el-button>
            </div>
            <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>

                <el-table-column align="center" label="字段编码">
                    <template slot-scope="scope">
                        <span>{{scope.row.fieldCode}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="字段值" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.fieldVal}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="步骤编号" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.stepNum }}</span>
                    </template>
                </el-table-column>
            </el-table>

        </div>
    </div>
</template>
<script>
import { splitterStageRuleRelLimitOptions, queryCommonInfoList } from "@/api/request";
const terminalMap = new Map();
const productMap = new Map();
const ruleMap = new Map();
const stageMap = new Map();
export default {
    name: "SplitterStageRuleRelLimit",
    data() {
        return {
            listLoading: false,
            queryData: { terminalId: undefined, userName: undefined, fieldCode: undefined },
            list: [],
            terminalList: [],
            productList: [],
        };
    },
    mounted() {
        this.getOptions().then(res => {
             this.queryData.terminalId=res.data[0].id
            this.terminalList = res.data;
            this.terminalList.forEach(e => terminalMap.set(e.id, e));
        });
    },
    filters: {
    },
    methods: {
        handleList() {
            if (!this.queryData.userName) {
                this.$message.error('请输入手机号码');
                return
            }
            this.listLoading = true;
            queryCommonInfoList("get", this.queryData)
                .then(res => {
                    this.list = res.data.userCommonInfoList;
                    this.listLoading = false;
                })
                .catch(_ => (this.listLoading = false,this.list=[]));
        },
        async getOptions(terminalId) {
            return await splitterStageRuleRelLimitOptions(terminalId);
        },

    }
};
</script>
<style lang="less" scoped>
.status-1-cls {
    color: #67c23a;
    font-weight: bold;
}
.status-0-cls {
    color: #f56c6c;
    font-weight: bold;
}
.extra-btn {
    float: right;
    margin-top: -72px;
    display: flex;
    margin-right: -30px;
    i {
        margin: auto 5px;
        font-size: larger;
        color: #909399;
    }
}
</style>
<style>
.custom-message {
    box-shadow: 0px 0px 10px #888888;
}
</style>
